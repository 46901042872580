import React from 'react'
import BarradeNavegacion from '../../components/navegacionBarAll/barradeNavegacion'
import "./adminPage.css"

function AdminPage() {
  return (
    <div>
        <BarradeNavegacion/>
    </div>
  )
}

export default AdminPage                                                                                                          