import React from 'react'
import "./vacios.css"
function Vacios() {
  return (
    <div className='container-vacio-p'>
      
    </div>
  )
}

export default Vacios
